import React, { useEffect, useState } from "react";
import { Link, graphql } from 'gatsby'
import loadable from "@loadable/component";
import Layout from "../components/layout";
import SEO from "../components/seo"
import Banner from "../components/Banner/Banner";
import Banner_Cta from "../components/Banner_Cta/Banner_Cta";
import TileBlock from "../components/TileBlock/TileBlock";
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule";
import Head from "../components/Head/Head";
import ContactComponent from "../components/ContactComponent/ContactComponent";
import TeamSlider from "../components/TeamSlider/TeamSlider"
import TestimonialSlider from "../components/TestimonialSlider/TestimonialSlider";
import FeaturedProperties from "../components/FeaturedProperties/FeaturedProperties"
import Statistics from "../components/Statistics/Statistics";
import CareerListing from "../components/Career/Listing";
import PlansComponent from "../components/PlansComponent/PlansComponent";
import LocRatingMap from '../components/map/loc-rating-map';
import { MapMarkerInfoBox } from '../components/map/map-marker'
import Instagram from "../components/Instagram/Instagram";
import ReviewsSlider from "../components/ReviewsSlider/ReviewsSlider";
import TeamSliderContact from "../components/TeamSliderContact/TeamSliderContact";
import { homeValuation, instantValuation } from "../site/urls";
import AboutStructuredData from "../components/Seo/AboutStructuredData";
import ContactStructuredData from "../components/Seo/ContactStructuredData";


const LandingTemplate = ({ data, location }, props) => {
  const locationState = location.state
  const PageData = data?.strapiPage
  let mapItems = [];
    let mapItem = {};
    mapItem['lat'] = PageData?.latitude
    mapItem['lng'] = PageData?.longitude
    const mapMarkerImageShowFlag = 0;
    let mapItemHtml = MapMarkerInfoBox("test", mapMarkerImageShowFlag);
    mapItem['html'] = mapItemHtml;
    mapItems.push(mapItem);
    var arr = false;
    if(PageData?.add_page_modules && PageData?.add_page_modules?.length > 0 && PageData?.add_page_modules[PageData?.add_page_modules?.length - 1]?.__typename === "STRAPI__COMPONENT_PAGE_MODULES_CTA_BANNER"){
      arr = true
    }
  return (
    <Layout popularSearch={PageData?.select_popular_search?.title} PageData={PageData}>
       <div className="bottom-right-image-home">
        <div className="hover-block">
          <div className="block-elements">
           {/* <Link to={instantValuation}>Instant Online Valuation</Link> */}
           {/* <div className="vertical-line"></div> */}
           <Link to={homeValuation}>Home Visit Valuation</Link>
           </div>
        </div>
        <div className="banner-img"></div>
     </div>
      {PageData.title==="Contact"&&
      <ContactStructuredData description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title}/>
      }
      {PageData.title==="About"&&
        <AboutStructuredData/>
      }
      <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
      <BreadcrumbModule choose_menu={PageData.choose_menu} pagename={PageData.title} />
      {PageData.layout === "no_banner_page" ?
        <Head title={PageData?.title} content={PageData?.content} tag={PageData?.title} />
        : 
        <Banner
        tag="landing"
        title={PageData.title}
        banner={PageData.banner}
        strapi_id={PageData.strapi_id}
        imagetransforms={
          PageData.imagetransforms
        }
      />
      }
 <div 
    className={arr ? "padding-bottom-banner-cta" : ""}
    >
      {PageData.add_page_modules?.map((item, i) => {
        return (
          <div>
            {item?.__typename === "STRAPI__COMPONENT_PAGE_MODULES_CTA_BANNER" &&
              <Banner_Cta {...item} />
            }
            {item?.__typename === "STRAPI__COMPONENT_PAGE_MODULES_IMAGE_TAILS" &&
              <TileBlock title={PageData.title} imagetransforms={
                PageData.imagetransforms
              } strapi_id={PageData?.strapi_id} {...item} />
            }
            {item?.__typename === "STRAPI__COMPONENT_PAGE_MODULES_STATISTICS" &&
              <Statistics  {...item} />
            }
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
              item?.select_module === "contact" && (
                <ContactComponent locationState={locationState} {...item} />
              )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
              item?.select_module === "team" && (
                <TeamSlider item={item} PageData={PageData} />
              )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_TESTIMONIAL" && (
                <TestimonialSlider pagetitle={PageData.title} imagetransforms={
                  PageData.imagetransforms
                } strapi_id={PageData?.strapi_id} {...item} />
              )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
              item?.select_module === "featured_properties" && (
                <FeaturedProperties {...item} />
              )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
              item?.select_module === "contact_map" && (
                PageData?.latitude && PageData?.longitude &&
                  <div className="area-guide-map">
                      <LocRatingMap data={mapItems} />
                  </div>
              
              )}
                           {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
              item?.select_module === "instagram" && (
            <Instagram {...item}  />
            )}
               {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_PLANS" &&
                <PlansComponent planData={item}/>
              }

              {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
              item?.select_module === "google_reviews_slider" && (
                <ReviewsSlider {...item} />
              )}

              {item?.__typename ===
              "STRAPI__COMPONENT_COMPONENTS_TEAM_SELECT" &&
               (
                <TeamSliderContact item={item} PageData={PageData} />
              )}

              {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
              item?.select_module === "career" && (
                <CareerListing {...item} />
              )}

          </div>
        )
      })}

      </div>

    </Layout>
  )
}

export default LandingTemplate


export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
      ...PageFragment
      show_search
        imagetransforms {
          banner_section_banner_image_Transforms
          banner_section_logo_image_Transforms
          image_tails_tail_image_Transforms
          testimonial_image_Transforms
        }
        add_page_modules {
          ... on STRAPI__COMPONENT_PAGE_MODULES_CTA_BANNER {
            __typename
            ...CTA_BANNERFragment
          }
          ... on STRAPI__COMPONENT_PAGE_MODULES_TESTIMONIAL {
            __typename
            ...TESTIMONIALFragment
          }
          ... on STRAPI__COMPONENT_PAGE_MODULES_STATISTICS {
            __typename
            ...STATISTICSFragment
          }
          ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
            __typename
            select_module
            title
          }
          ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_TAILS {
            __typename
            ...IMAGE_TAILSFragment
          }
          ... on STRAPI__COMPONENT_PAGE_MODULES_PLANS {
            __typename
            ...PLANSFragment
          }
          ... on STRAPI__COMPONENT_COMPONENTS_TEAM_SELECT{
            __typename
            ...TeamMemberSelectFragment
          }
        }
    }
  }
`