import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql, Link, useStaticQuery } from "gatsby"
import ScrollAnimation from "react-animate-on-scroll"
import { career } from "../../site/urls"
import "./Listing.scss"

const TeamSlider = props => {
    const { PageData, item } = props
    const allJobData = useStaticQuery(graphql`
      query {
        allStrapiCareer(filter: {publish: {eq: true}}) {
            edges {
              node {
                title
                slug
                department
              }
            }
        }
      }
    `)
    const jobList = allJobData?.allStrapiCareer?.edges
  
    return (
      <div className="career-listing-section">
        <Container>
            <Row>
                <Col>
                <h2 className="main-title">Current Vacancies</h2>
                <div className="job-lists">
                    {jobList && jobList.length > 0 && jobList.map(({node}, index) => {
                        return (
                            <div className="job-card">
                                <Row>
                                    <Col md={8}><div className="title">{node.title}</div></Col>
                                    <Col md={2}>{node.department}</Col>
                                    <Col md={2}><Link className="link" to={`${career}${node.slug}/`}>View Details</Link></Col>
                                </Row>
                            </div>
                        )
                    })}
                </div>
                </Col>
            </Row>
        </Container>
      </div>
    )
  }
  
  export default TeamSlider
  