import React, { useState } from "react";
import './Statistics.scss';
import { Container, Row, Col, Modal } from "react-bootstrap"
import ContentModule from "../../modules/content-render";
import ImageModule from "../../modules/image-render";
import { Link } from "gatsby";
import { PageLinks } from "../../common/site/page-static-links";
import ScrollAnimation from "react-animate-on-scroll";
import FormFields from "../../../static/forms/team_form.json"
import DefaultForm from "../forms/default-form-layout";

const Statistics = (props) => {
    const [show, setShow]=useState(false)
    var imagename = "team.image.team_member_image";

    const handleModal=()=>{
        setShow(true)
    }

    const closeModal = () => {
        setShow(false)
      }
    

    return (
        <div className="statistics section-p">
            <Container>
                <Row>
                    <Col xl={9}>
                    <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={10}>
                        <h2>{props?.title}</h2>
                        </ScrollAnimation>
                    </Col>
                    <Col xl={7}>
                    <ScrollAnimation className="st-bk" animateIn="animate__slideInUp" animateOnce delay={200} offset={10}>
                        <ContentModule Content={props.content?.data?.content} />
                        </ScrollAnimation>
                    </Col>
                    <Col xl={1}></Col>
                    <Col xl={4}>
                    <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={300} offset={10}>
                        <h4 className="heading_team_member">{props?.team_title}</h4>
                        {props?.choose_team?.length> 0 &&
                            <ul className="team-member-ul">
                                {props?.choose_team?.map((item, i) => {
                                        let processedImages = JSON.stringify({});
                                        if (item?.imagetransforms) {
                                            processedImages = item?.imagetransforms.image_Transforms;
                                        }
                                    return(
                                        <li>
                                            <div className="member-img img-zoom">
                                            <Link to={"/"+PageLinks.team+"/"+item.slug+"/"}><ImageModule ImageSrc={item?.image} altText={`${item?.image?.alternativeText ? item?.image?.alternativeText : item.name}`} imagetransforms={processedImages} renderer="srcImg" imagename={imagename} strapi_id={item?.strapi_id} classNames="img-fluid banner-img" /></Link>
                                            </div>
                                            <div className="mem-details">
                                                <div className="name"><Link to={"/"+PageLinks.team+"/"+item.slug+"/"}>{item.name}</Link></div>
                                                <div className="designation">{item.designation}</div>
                                                <a onClick={handleModal} href="javascript:void(0)" className="mem-link">Contact</a>
                                                <Modal
                                                    show={show}
                                                    onHide={() => setShow(false)}
                                                    aria-labelledby="contained-modal-title-vcenter"
                                                    centered
                                                    className="more-filters-modal contact-form"
                                                    id="filter-modal"
                                                    // fullscreen={"xl-down"}
                                                    backdrop="static"
                                                >
                                                    <button
                                                    className="cancel-button"
                                                    type="button"
                                                    onClick={() => closeModal()}
                                                    >
                                                    <i className="icon black-cancel" />
                                                    </button>
                                                    <Modal.Body>
                                                    <div className="modal-body-container">
                                                        <h2>Open a Conversation</h2>
                                                        <div className="contact-text">
                                                        Please contact us using the form below or call us on{" "}
                                                        <a href={"tel:" + "+44 (0)207284 1222"}>
                                                            +44 (0)20 7284 1222
                                                        </a>
                                                        .
                                                        </div>
                                                        <DefaultForm
                                                        fields={FormFields}
                                                        formtagclassname="team-form"
                                                        to_email_id={item?.email}
                                                        />
                                                    </div>
                                                    </Modal.Body>
                                                </Modal>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        }
                        </ScrollAnimation>
                    </Col>
                </Row>
                {props?.stats?.length > 0 &&
                    <Row className="stat-bk">
                        {props?.stats?.map((item, i) => {
                            return (
                                    <Col xl={4} md={6}>
                                        <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={(i+1) * 100} offset={25}>
                                        <div className="stat-value">
                                            {item?.stats_value}
                                        </div>
                                        <div className="stat-content">
                                            {item?.content}
                                        </div>
                                        </ScrollAnimation>
                                    </Col>
                            )
                        })}

                    </Row>
                }
            </Container>
        </div>
    )
}

export default Statistics