import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import "./TeamCard.scss";
import PlayVideo from "../PlayVideo/PlayVideo";
import useDeviceMedia from "../../hooks/useDeviceMedia";
const ImageModule = loadable(() => import("../../modules/image-render"));
const TestimonialCard = ({name, designation,image,video_url, imagetransforms, strapi_id}) => {
  const [isPlay, setPlay] = useState(false)
  var imagename = "page.testimonial_image.image";
  const {isMobile}=useDeviceMedia()
  let processedImages = JSON.stringify({});
    if (imagetransforms?.testimonial_image_Transforms) {
        processedImages = imagetransforms?.testimonial_image_Transforms;
    }
  return (
    <div className="team-card-section">
      <div className="image-section img-zoom">
      <a><ImageModule ImageSrc={image} altText={image?.alternativeText} imagetransforms={processedImages} renderer="pic-src" imagename={imagename} strapi_id={strapi_id} classNames="img-fluid" /></a>
                        {video_url &&
                        <button onClick={() => setPlay(true)} className="testimonial-play-button">
                        <div className="testimonial-play-btn d-flex align-items-center justify-content-center">
                          {isMobile?  <i className="icon icon-play-blacky"></i> :<i className="icon icon-play"></i>}
                        </div>
                    </button>}
                        {isPlay && video_url && (
                        <PlayVideo
                            isOpen={isPlay}
                            stopPlay={setPlay}
                            videoId=""
                            isCloseFunction={setPlay}
                            videourl={video_url}
                            htmlink={""}
                        />
                    )}

      </div>
      <div className="content">
        <p className="name">{name}</p>
        <p className="designation">{designation}</p>
      </div>
    </div>
  )
}

export default TestimonialCard
