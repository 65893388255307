import { Link } from "gatsby"
import React from "react"
import "./TeamCard.scss"
import { ourPeople } from "../../site/urls"
import ImageModule from "../../modules/image-render"

const TeamCard = ({ name, designation, image, slug, imagetransforms, id }) => {
  let processedImages = JSON.stringify({})
  if (imagetransforms?.image_Transforms) {
    processedImages = imagetransforms.image_Transforms
  }
  var imagename = "team.image.tile_image"
  return (
    <div className="team-card-section">
      <div className="image-section img-zoom">
        <Link to={`${ourPeople}${slug}/`}>
          <ImageModule
            ImageSrc={image}
            altText={`${
              image?.alternativeText ? image?.alternativeText : name
            } banner`}
            imagetransforms={processedImages}
            renderer="pic-src"
            imagename={imagename}
            strapi_id={id}
            classNames="team-image"
          />
        </Link>
      </div>
      <div className="content">
        <Link to={`${ourPeople}${slug}/`}>
          <p className="name">{name}</p>
        </Link>
        <p className="designation">{designation}</p>
      </div>
    </div>
  )
}

export default TeamCard
