import React, { useState } from "react";
import './Head.scss';
import { Container, Row, Col } from "react-bootstrap";
import ContentModule from "../../modules/content-render";
import ScrollAnimation from "react-animate-on-scroll";
const Head = (props) => {

    

    return (
        <section className={`head-block`}>
            <Container>
                <Row>
                    {
                        props.tag === "Contact" ?
                        <>
                            <Col xl={3}>
                                <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={10}>
                                    <h1>{props?.title}</h1>
                                </ScrollAnimation>
                            </Col>
                            <Col xl={3}></Col>
                            <Col xl={6}>
                                <div className="head-para">
                                    <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={200} offset={10}>
                                    {props.content && <ContentModule Content={props.content?.data?.content} />}
                                    </ScrollAnimation>
                                </div>
                            </Col>
                        </>
                        :
                        <>
                            <Col xl={2}>
                                <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={10}>
                                    <h1>{props?.title}</h1>
                                </ScrollAnimation>
                            </Col>
                            <Col xl={2}></Col>
                            <Col xl={8}>
                                <div className="head-para">
                                    <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={200} offset={10}>
                                    {props.content && <ContentModule Content={props.content?.data?.content} />}
                                    </ScrollAnimation>
                                </div>
                            </Col>
                        </>
                    }
                </Row>
            </Container>
        </section>
    )
}

export default Head