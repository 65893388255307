import React from "react"
import { Container } from "react-bootstrap"
import CustomSliderNew from "../CustomSlider/CustomSliderNew"
import TeamCard from "../TeamCard/TeamCard"
import "./TeamSlider.scss"
import { graphql, Link, useStaticQuery } from "gatsby"
import { ourPeople } from "../../site/urls"
import ScrollAnimation from "react-animate-on-scroll"

const TeamSlider = props => {
  const { PageData, item } = props
  const allTeamData = useStaticQuery(graphql`
    query {
      allStrapiTeam {
        edges {
          node {
            image {
              url
            }
            id
            imagetransforms {
              image_Transforms
            }
            slug
            name
            phone
            strapi_id
            designation
          }
        }
      }
    }
  `)
  const teamList = allTeamData?.allStrapiTeam?.edges?.slice(0, 6)

  return (
    <div className="team-slider-section">
      <Container>
        {PageData?.page_name === "contact" ? (
          <div className="team-slider-contact-page">
            <ScrollAnimation animateIn="animate__slideInUp" duration={.5} animateOnce delay={100} offset={100}>
            <h2 className="team-slider-title">
              {item.title ? item.title : "More of our team"}
            </h2>
            <div className="vertical-line"></div>
            <div className="our-people-link">
              <Link to={ourPeople}>MEET THE TEAM</Link>
            </div>
            </ScrollAnimation>
          </div>
        ) : (
          <h2 className="team-slider-title">More of our team</h2>
        )}
        <CustomSliderNew className="team-slider-wrap">
          {teamList?.map((team, i) => (
            <ScrollAnimation animateIn="animate__slideInUp" duration={.5} animateOnce delay={(i+1) * 100} offset={25}>
            <TeamCard
              name={team.node?.name}
              designation={team.node?.designation}
              image={team.node?.image}
              slug={team.node?.slug}
              imagetransforms={team.node?.imagetransforms}
              id={team.node?.strapi_id}
            />
            </ScrollAnimation>
          ))}
        </CustomSliderNew>
      </Container>
    </div>
  )
}

export default TeamSlider
