import React from "react"
import { Container } from "react-bootstrap"
import CustomSliderNew from "../CustomSlider/CustomSliderNew"
import "./TestimonialSlider.scss"
import { ourPeople } from "../../site/urls"
import TestimonialCard from "../TeamCard/TestimonialCard"
import ScrollAnimation from "react-animate-on-scroll"

const TestimonialSlider = props => {
  return (
    <div className={`testimonial-slider-section section-p ${props.pagetitle === "Careers" ? 'no-bg' : ''}`}>
      <Container>
      <ScrollAnimation animateIn="animate__slideInUp" duration={.5} animateOnce delay={100} offset={10}>
        <h2 className="testimonial-slider-title">{props?.title}</h2>
        </ScrollAnimation>
        <CustomSliderNew className="testimonial-slider-wrap">
          {props?.add_item?.map((team, i) => (
            <ScrollAnimation animateIn="animate__slideInUp" duration={.5} animateOnce delay={(i+1) * 100} offset={25}>
            <TestimonialCard
              name={team?.name}
              designation={team?.position}
              image={team?.image}
              video_url={team?.video_url}        
              strapi_id={props?.strapi_id}      
              imagetransforms={props?.imagetransforms}
            />
            </ScrollAnimation>
          ))}
        </CustomSliderNew>
      </Container>
    </div>
  )
}

export default TestimonialSlider
