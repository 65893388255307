import React from "react"
import { Helmet } from "react-helmet"

function ContactStructuredData({ description }) {
  const site_url = process.env.GATSBY_SITE_URL

  var ldJson = {
    "@context": "http://www.schema.org",
    "@type": "RealEstateAgent",
    name: "Olivers Town estate agents in [area]",
    url: site_url,
    logo: "https://ggfx-oliverstown.s3.eu-west-2.amazonaws.com/i.prod/logo_a1747dfb98.png",
    image: "https://ggfx-oliverstown.s3.eu-west-2.amazonaws.com/i.prod/logo_a1747dfb98.png",
    description: description,
    address: {
      "@type": "PostalAddress",
      streetAddress: "189 Kentish Town Road",
      addressLocality: "Kentish Town",
      postalCode: "NW5 2JU",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: "51.546640",
      longitude: "-0.141940",
    },
    openingHours: "[Mon - Fri:[8:30am - 6pm], Sat: [9.30am - 3pm], Sun: Closed",
    telephone: "+44 (0) 20 7284 1222",
    contactPoint: {
      "@type": "ContactPoint",
      contactType: "Sales",
      telephone: "+44 (0) 20 7284 1222",
    },
  }
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>
    </Helmet>
  )
}

export default ContactStructuredData
