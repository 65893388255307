import React from "react"
import { Container } from "react-bootstrap"
import CustomSliderNew from "../CustomSlider/CustomSliderNew"
import TeamCard from "../TeamCard/TeamCard"
import "./TeamSliderContact.scss"
import { graphql, Link, useStaticQuery } from "gatsby"
import { ourPeople } from "../../site/urls"
import ScrollAnimation from "react-animate-on-scroll"

const TeamSliderContact = (props) => {
    const { item } = props
    const teamList=item?.team_list
    const pageData=props.PageData;
    
  return (
    <div className="team-slider-section">
     <Container>
     <ScrollAnimation animateIn="animate__slideInUp" duration={.5} animateOnce delay={100} offset={100}>
        <div className="team-slider-contact-page">
          <h2 className="team-slider-title">
           {pageData.title==="Careers"?"What do our staff have to say?":"Key Connections"}
          </h2>
          {pageData.title!=="Careers"&&
          <>
          <div className="vertical-line"></div>
          <div className="our-people-link">
            <Link to={ourPeople}>MEET THE TEAM</Link>
          </div>
          </>
          }
        </div>
        </ScrollAnimation>
      
      <CustomSliderNew className="team-slider-wrap">
        {teamList?.map((list, i) => (
          <ScrollAnimation animateIn="animate__slideInUp" duration={.5} animateOnce delay={(i+1) * 100} offset={25}>
          <TeamCard
            name={list.team?.name}
            designation={list.team?.designation}
            image={list.team?.image}
            slug={list.team?.slug}
            imagetransforms={list.team?.imagetransforms}
            id={list.team?.strapi_id}
          />
          </ScrollAnimation>
        ))}
      </CustomSliderNew>
    </Container> 
  </div>
  )
}

export default TeamSliderContact