import React from "react"
import { Helmet } from "react-helmet"

function AboutStructuredData() {
  var ldJson = {
    "@context": "http://schema.org",
    "@type": "RealEstateAgent",
    Name: "Olivers Town Estate Agents",
    URL: "https://oliverstown.q.starberry.com/",
    Image: "https://ggfx-oliverstown.s3.eu-west-2.amazonaws.com/i.prod/Rectangle_16_44189786cf.jpg",
    Logo: "https://oliverstown.com/wp-content/uploads/2022/09/logo.svg",
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>
    </Helmet>
  )
}

export default AboutStructuredData
