import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Modal } from "react-bootstrap"
import parse from "html-react-parser"
import "./ContactComponent.scss"
import FormFields from "../../../static/forms/contact_form.json"
import DefaultForm from "../forms/default-form-layout"
import { generalEnquiry } from "../../site/urls"
import ScrollAnimation from "react-animate-on-scroll"

const ContactComponent = (props) => {
  const contactData = useStaticQuery(graphql`
    query {
      strapiGlobalMenu {
        contact_detail {
          email
          phone
          title
          id
          content {
            data {
              content
            }
          }
        }
      }
    }
  `)

  const contactList = contactData?.strapiGlobalMenu?.contact_detail

  const [show, setShow] = useState(false)

  const handleModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }
  const {locationState} = props
  useEffect(() => {
      setShow(locationState?.form)
  }, [locationState])
  
  return (
    <div className="contact-component-wrapper">
      <Container>
        <div className="contact-us-module">
          {contactList?.map((list, index) => (
            <div className={`contact-list list-${index}`}>
              <ScrollAnimation animateIn="animate__slideInUp" duration={.5} animateOnce delay={(index+1) * 100} offset={25}>
              <div className="vertical-line"></div>
              <p className="contact-title">{list.title}</p>
              <div className="contact-section">
                {list.phone && <p className="phone-num"><Link to={`tel:${list.phone}`}>{list.phone}</Link></p>}
                {list.email && (
                  <p>
                  <a href="javascript:void()" onClick={handleModal} className="email-address" >
                    {list.email}
                  </a>
                  </p>
                )}
                {list.content?.data?.content && (
                  <p className="description">
                    {parse(list.content?.data?.content)}
                  </p>
                )}
              </div>
              </ScrollAnimation>
            </div>
          ))}
        </div>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="more-filters-modal contact-form"
          id="filter-modal"
          // fullscreen={"xl-down"}
          backdrop="static"
        >
          <button
            className="cancel-button"
            type="button"
            onClick={() => closeModal()}
          >
            <i className="icon black-cancel" />
          </button>
          <Modal.Body>
            <div className="modal-body-container">
              <h2>Open a Conversation</h2>
              <div className="contact-text">
                Please contact us using the form below or call us on{" "}
                <a href={"tel:" + "+44 (0)207284 1222"}>+44 (0)20 7284 1222</a>.
              </div>
              <DefaultForm
                fields={FormFields}
                formtagclassname="contact-form"
              />
            </div>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  )
}

export default ContactComponent
