import React, { useEffect, useRef, useState } from "react"
import "./ReadMore.scss"
import parse from "html-react-parser"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const ReadMore = ({ content, height, className }) => {
  const { isMobile,tabletAll } = useDeviceMedia()
  const [showReadMore, setShowReadMore] = useState(false)
  const [readMore, setReadMore] = useState(true)
  const maxHeight = isMobile ? 141 :tabletAll? 212:400

  const elementRef = useRef()

  useEffect(() => {
    
    if (elementRef.current.clientHeight > maxHeight) {
      setShowReadMore(true)
    }
    else{
        setShowReadMore(false)
    }
 

    if (readMore) {
      elementRef.current.style.maxHeight = maxHeight + "px"
    } else {
      elementRef.current.style.maxHeight = ""
    }
  }, [readMore])

  if (!content) return null


  return (
    <div className={"read-more-wrap"}>
      <div
        ref={elementRef}
        className={`${
          showReadMore ? "read-more-section" : readMore ? "read-more" : "read-more-sec"
        }`}
      >
        {parse(content)}
      </div>
        <button
          onClick={() => setReadMore(val => !val)}
          className={`read-more-button ${readMore ? "" : "read-less"}`}
        >
          {readMore?
           <div>
            <span>Show more Features</span>
             <i className="icon plan-down-arrow"/>
           </div> : 
           <div>
           <span>Show less Features</span>
            <i className="icon plan-down-arrow"/>
          </div>
           }
        </button>
    </div>
  )
}

export default ReadMore
