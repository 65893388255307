import React from "react";
import { Link, navigate } from "gatsby";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import './Breadcrumb-module.scss';

const BreadcrumbModule = (props) => {

    const handleBack = (props) => { 
            if(props.alias==="sales"&&props.department==="residential"){
                navigate("/properties/for-sale/")
            }
            else if(props.alias==="lettings"&&props.department==="residential"){
                navigate("/properties/to-rent/")
            }
            else if(props.alias==="sales"&&props.department==="new_developments"){
                navigate("/new-homes/for-sale/")
            }
            else{
                navigate("/auction-property/for-sale/")
            }
      }
    // console.log(props?.choose_menu)
    var page = '';
    var subsubpage = '';
    var subsubtitle = '';
    var subpage = '';
    var subtitle = '';
    if(props?.choose_menu?.length > 0){
        if(props?.choose_menu[0]?.strapi_parent?.strapi_parent?.slug){
            subsubpage = '/'+props?.choose_menu[0]?.strapi_parent?.strapi_parent?.slug+'/';
            subsubtitle = props?.choose_menu[0]?.strapi_parent?.strapi_parent?.title;
            subpage = '/'+props?.choose_menu[0]?.strapi_parent?.strapi_parent?.slug+'/'+props?.choose_menu[0]?.strapi_parent?.slug+'/';
            subtitle = props?.choose_menu[0]?.strapi_parent?.title;
        }
        else if(props?.choose_menu[0]?.strapi_parent?.slug){
            subpage = '/'+props?.choose_menu[0]?.strapi_parent?.slug+'/';
            subtitle = props?.choose_menu[0]?.strapi_parent?.title;
        } else{
            page = '/'+props?.choose_menu[0].slug+'/';
        }
    }

    return (
        <div className="breadcrumb-module-wrapper">            
            <Container>
                <Row>
                    <Col>
                        <Breadcrumb>
                        
                            <Link to="/" className="breadcrumb-item"><span>Home</span></Link>
                            {subsubpage && <Link to={`${subsubpage}`} className="breadcrumb-item"><span>{subsubtitle}</span></Link> }
                            {subpage && <Link to={`${subpage}`} className="breadcrumb-item"><span>{subtitle}</span></Link> }
                            {props.mainparent && <Link to={`/${props.mainparent}/`} className="breadcrumb-item"><span>{props.mainparentname}</span></Link> }
                            {props.parent && <Link to={`/${props.parent}/`} className="breadcrumb-item"><span>{props.parentname}</span></Link> }
                            {props.subparent && <Link to={`/${props.subparent}/`} className="breadcrumb-item">{props.subparentname}</Link> }
                            {props.type === "property-details-page" && props?.department === "auction" ? <Link to={`/property-auctions/`} className="breadcrumb-item"><span>Auctions</span></Link>
                            : props.type === "property-details-page" && props?.department === "new_developments" ? <Link to={`/new-homes/`} className="breadcrumb-item"><span>New Homes</span></Link>
                            : props.type === "property-details-page" && props?.alias === "sales" ? <Link to={`/sales/`} className="breadcrumb-item"><span>Sales</span></Link>
                            : props.type === "property-details-page" && props?.alias === "lettings" ? <Link to={`/lettings/`} className="breadcrumb-item"><span>Lettings</span></Link>                            
                            : null}
                           

                            {props.type === "property-details-page" &&
                  <a
                    // to={`/properties/${props?.alias === "sales" ? 'for-sale' : 'to-rent'}`}
                    onClick={() => handleBack(props)}
                    onKeyPress={() => handleBack()}
                    href="javascript:void(0)"
                    variant="none"
                    className="breadcrumb-item"

                  >
                 {/* {props?.alias === "sales"? 'Property for sale' : props?.alias === "lettings" ? 'Property to rent' : ""} */}

                    {props?.alias === "sales"&&props.department==="new_developments" ? 'New Homes for Sale':props?.alias === "sales"&&props.department==="residential"?"Properties for sale":props?.alias === "sales"&&props.department==="auction"?"Auctions for Sale" : props?.alias === "lettings" ? 'Property to rent' : ""}
                  </a>
                 
                }
                            <Breadcrumb.Item active>{props.pagename}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BreadcrumbModule