import React, { useState } from "react"
import parse from "html-react-parser"
import { Container, Modal } from "react-bootstrap"
import "./PlansComponent.scss"
import ReadMore from "../ReadMore/ReadMore"
import DefaultForm from "../forms/default-form-layout"
import FormFields from "../../../static/forms/property_management.json"
const PlansComponent = ({ planData }) => {

  const { add_plan, title } = planData
  const [show, setShow] = useState(false)
  const [plan, setplan] = useState("")
  const [planrate, setplanrate] = useState("")
  const handleModal = (title, content) => {
    setShow(true)
    setplan(title)
    setplanrate(content)
  }

  const closeModal = () => {
    setShow(false)
    setplan("")
    setplanrate("")
  }
  return (
    <div className="plan-component-wrapper">
      <Container>
        <div className="plan-section">
          <h2 className="plans-title">{title}</h2>
          <div className="plan-list-items">
            {add_plan?.map((plan, index) => (
              <div className="plan-list">
                <h4 className="main-title">{plan.title}</h4>
                <p className="sub-title">{plan.sub_title}</p>
                <ReadMore content={plan.content.data.content} />

                <div className="button-section">
                  <button onClick={() => handleModal(plan.title, plan.sub_title)} className="button button-filled">
                    {plan?.cta_link[0]?.label}
                  </button>
                  <p className="plan-contact">
                    or call{" "}
                    <a href={"tel:" + `${plan?.cta_link[0]?.telephone_number}`}>
                      {plan?.cta_link[0]?.telephone_number}.
                    </a>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="more-filters-modal contact-form"
                    id="filter-modal"
                    // fullscreen={"xl-down"}
                    backdrop="static"
                  >
                    <button
                      className="cancel-button"
                      type="button"
                      onClick={() => closeModal()}
                    >
                      <i className="icon black-cancel" />
                    </button>
                    <Modal.Body>
                      <div className="modal-body-container">
                        <h2>{plan}</h2>
                        <div className="contact-text">
                          {planrate}
                        </div>
                        <DefaultForm
                          fields={FormFields}
                          formtagclassname="team-form"
                          plan={plan}
                          email_subject_user={"Property Management: " +plan}
                          email_subject_admin={"Property Management: " +plan}
                        />
                      </div>
                    </Modal.Body>
                  </Modal>
      </Container>
    </div>
  )
}

export default PlansComponent
